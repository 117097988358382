import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import kv from '../images/service-kv.jpg'
import image1 from '../images/service1.png'
import image3 from '../images/service3.png'
import image5 from '../images/service5.png'

const Service = ({ location }) => (
  <Layout location={location} title="Service" subTitle="サービス" kv={kv}>
    <SEO title="サービス" />
    <section className="service-content">
      <ol>
        <li>
          <h2 className="service-content__heading">
            <span className="number">1</span>
            <div>
              <p className="title">太陽光発電システム</p>
              <p className="sub-title">販売・施工・メンテナンス等</p>
              <p className="description first">太陽光発電は環境に優しく持続可能なエネルギーとして注目され年々導入する住宅が増えております。太陽光発電で得た電気を利用することで、電気代の節約はもちろん使い切れなかった電気は蓄電や電力会社へ売ることが可能です。</p>
            </div>
          </h2>
          <figure className="service-content__image first">
            <img src={image1} alt="太陽光発電システム" />
          </figure>
        </li>
        <li>
          <h2 className="service-content__heading">
            <span className="number">2</span>
            <div>
              <p className="title">蓄電池</p>
              <p className="sub-title">販売・施工・メンテナンス等</p>
              <p className="description">太陽光発電から得た電気は、売る時代から蓄電する時代となりました。 発電して余った電気を蓄電池に貯めて自ら消費することで、発電しない時間帯は電力会社から限りなく電気を購入しないお家になります。また停電や災害などの緊急時にも活用できるので安心です。</p>
            </div>
          </h2>
        </li>
        <li>
          <h2 className="service-content__heading third">
            <span className="number">3</span>
            <div>
              <p className="title">オール電化</p>
              <p className="sub-title">販売・施工・メンテナンス等</p>
              <p className="description">エコキュートやIHクッキングヒーターの設置でガス代０のお家。深夜の余った電気を使用するエコキュートは、空気の圧縮時に生じる熱を利用してお湯を沸かすので環境に優しく電気代の節約になります。また現在のIHクッキングヒーターの火力はガスの1.5倍程ありお料理も楽々。お手入れ簡単なフラット構造も魅力です。</p>
            </div>
          </h2>
          <figure className="service-content__image">
            <img src={image3} alt="オール電化" />
          </figure>
        </li>
        <li>
          <h2 className="service-content__heading fourth">
            <span className="number">4</span>
            <div>
              <p className="title">エクステリア</p>
              <p className="sub-title">販売・施工・メンテナンス等</p>
              <p className="description">車の汚れ等を防ぐカーポートやウッドデッキ、ベランダ屋根、人工芝など、お家の外回りを再考・創造し、より快適な理想の「エクステリア」空間を整えます。</p>
            </div>
          </h2>
        </li>
        <li>
          <h2 className="service-content__heading">
            <span className="number">5</span>
            <div>
              <p className="title">ファイナンシャルプランナー</p>
              <p className="sub-title">による家計見直しサービス</p>
              <p className="description">太陽光発電やエクステリアの施工をはじめ、住宅ローンの見直しや火災保険などの活用方法もご提供しております。お客様の生活の負担を抑えた安心で心地よい毎日を過ごしていただきたいという想いを込めて、弊社に在籍するファイナンシャルプランナーによる無料の家計見直し相談サービスを行っています。</p>
            </div>
          </h2>
          <figure className="service-content__image fifth">
            <img src={image5} alt="ファイナンシャルプランナー" />
          </figure>
        </li>
      </ol>
    </section>
  </Layout>
)

export default Service
